import styles from './Logo.module.scss'
import classNames from 'classnames/bind'

import PrimaryLogo from '@/images/logo/logo-primary.svg'
import WhiteLogo from '@/images/logo/logo-white.svg'

const classes = classNames.bind(styles)

const Logo = ({ type = 'primary', ...props }) => {
    return (
        <div className={classes('wrapper')} {...props}>
            {type == 'primary' ? <PrimaryLogo /> : <WhiteLogo />}
        </div>
    )
}

export default Logo
