import Button from '@/components/Links/Button/Button'
import styles from './SpecialOffer.module.scss'
import classNames from 'classnames/bind'
import Media from '../Media/Media'
import WindowSizeListener from '@/components/Helpers/WindowSizeListener/WindowSizeListener'

import Cross from '@/icons/cross.svg'
import { CSSTransition } from 'react-transition-group'
import { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'

const classes = classNames.bind(styles)

const SpecialOffer = ({ open, setOpen, title, description, image, extraHeightTop }) => {
    const nodeRef = useRef(null)
    const [loaded, setLoaded] = useState(false)
    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        WindowSizeListener('959px', setMobile)
        setLoaded(true)
    }, [])

    if (!loaded) return null

    return mobile ? (
        createPortal(
            <Overlay
                open={open}
                setOpen={setOpen}
                popupData={{ title, description, image }}
                extraHeightTop={extraHeightTop}
            />,
            document.body
        )
    ) : (
        <CSSTransition
            unmountOnExit
            in={open}
            timeout={300}
            classNames={{
                enter: classes('animate-enter'),
                enterActive: classes('animate-enter-active'),
                exit: classes('animate-exit'),
                exitActive: classes('animate-exit-active'),
            }}
            nodeRef={nodeRef}
        >
            <div className={classes('wrapper')} ref={nodeRef}>
                <Media attributes={image?.data?.attributes} className={classes('image')} />
                <div className={classes('content')}>
                    <h3 className={classes('title')}>{title}</h3>
                    <p className={classes('description')}>{description}</p>
                </div>
                <Button text="Start now!" type="primary" extraClasses={classes('button')} />
                <div className={classes('close')} onClick={() => setOpen(false)}>
                    <Cross />
                </div>
            </div>
        </CSSTransition>
    )
}

const Overlay = ({ open, setOpen, popupData: { title, description, image }, extraHeightTop }) => {
    const overlayRef = useRef(null)
    const nodeRef = useRef(null)
    const [showPopup, setShowPopup] = useState(false)

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setShowPopup(true)
            }, 50)
        } else {
            setShowPopup(false)
        }
    }, [open])

    return (
        <CSSTransition
            unmountOnExit
            in={open}
            timeout={300}
            classNames={{
                enter: classes('overlay-animate-enter'),
                enterActive: classes('overlay-animate-enter-active'),
                exit: classes('overlay-animate-exit'),
                exitActive: classes('overlay-animate-exit-active'),
            }}
            nodeRef={overlayRef}
        >
            <div className={classes('overlay')} ref={overlayRef} onClick={() => setOpen(false)}>
                <CSSTransition
                    unmountOnExit
                    in={showPopup}
                    timeout={400}
                    classNames={{
                        enter: classes('animate-enter'),
                        enterActive: classes('animate-enter-active'),
                        exit: classes('animate-exit'),
                        exitActive: classes('animate-exit-active'),
                    }}
                    nodeRef={nodeRef}
                >
                    <div className={classes('wrapper', { 'extra-height': extraHeightTop })} ref={nodeRef}>
                        <Media attributes={image?.data?.attributes} className={classes('image')} />
                        <div className={classes('content')}>
                            <h3 className={classes('title')}>{title}</h3>
                            <p className={classes('description')}>{description}</p>
                        </div>
                        <Button text="Start now!" type="primary" extraClasses={classes('button')} />
                        <div className={classes('close')} onClick={() => setOpen(false)}>
                            <Cross />
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </CSSTransition>
    )
}

export default SpecialOffer
