'use client'

import { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'

import { CSSTransition } from 'react-transition-group'

import AnimateHeight from 'react-animate-height'
import Button from '@/components/Links/Button/Button'
import Logo from '@/components/Elements/Logo/Logo'

import ChevronDown from '@/icons/chevron-down.svg'
import MenuIcon from '@/icons/menu.svg'
import CloseIcon from '@/icons/close.svg'
import Facebook from '@/icons/facebook-gray.svg'
import LinkedIn from '@/icons/linkedin-gray.svg'
import Instagram from '@/icons/instagram-gray.svg'

import styles from './MobileMenu.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

const MobileMenuButton = ({ navbarType, button }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <>
            <MenuIcon
                className={classes('menu-icon', {
                    'icon-dark': navbarType == 'default',
                })}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            />
            <MobileMenu open={mobileMenuOpen} setOpen={setMobileMenuOpen} button={button} />
        </>
    )
}

const MobileMenu = ({ open, setOpen, button }) => {
    // Set state to determine if the browser is loaded, default to false.
    const [browserLoaded, setBrowserLoaded] = useState(false)
    const [pricingLink, setPricingLink] = useState('/#pricing-section')
    const [currentExpanded, setCurrentExpanded] = useState(null)

    const mobileMenuRef = useRef(null)
    const nodeRef = useRef(null)

    useEffect(() => {
        setPricingLink(document.getElementById('pricing-section') ? '#pricing-section' : '/#pricing-section')
        setBrowserLoaded(true)
    }, [])

    if (!browserLoaded) return null

    return createPortal(
        <CSSTransition
            in={open}
            unmountOnExit
            timeout={300}
            classNames={{
                enter: classes('mobile-menu-enter'),
                enterActive: classes('mobile-menu-enter-active'),
                exit: classes('mobile-menu-exit'),
                exitActive: classes('mobile-menu-exit-active'),
            }}
            nodeRef={nodeRef}
        >
            <div
                className={classes('overlay')}
                onClick={({ target }) => {
                    if (target != mobileMenuRef.current && !mobileMenuRef.current.contains(target)) {
                        setOpen(false)
                    }
                }}
                ref={nodeRef}
            >
                <div className={classes('wrapper')} ref={mobileMenuRef}>
                    <div className={classes('top-section')}>
                        <a href="/" title="@Taxd logo link to homepage">
                            <Logo className={classes('logo')} />
                        </a>

                        <CloseIcon className={classes('close-icon', 'menu-icon')} onClick={() => setOpen(false)} />
                    </div>
                    <div className={classes('sidebar-stretch-wrapper')}>
                        <div className={classes('content-wrapper')}>
                            <div className={classes('menu-item')}>
                                <a href={pricingLink} onClick={() => setOpen(false)}>
                                    Pricing
                                </a>
                            </div>
                            <div className={classes('menu-item', { open: currentExpanded == 'services' })}>
                                <a
                                    href={'#'}
                                    className={classes({ 'child-open': currentExpanded == 'services' })}
                                    onClick={() =>
                                        setCurrentExpanded(currentExpanded == 'services' ? null : 'services')
                                    }
                                >
                                    Services
                                    <ChevronDown className={classes('chevron-down')} />
                                </a>
                                <AnimateHeight
                                    animateOpacity={true}
                                    duration={300}
                                    height={currentExpanded == 'services' ? 'auto' : 0}
                                    contentClassName={classes('child-menu')}
                                >
                                    <div className={classes('child-group')}>
                                        <a className={classes('group-title')}>Services</a>
                                        <a className={classes('child-link')} href={'/personal'}>
                                            Personal
                                        </a>
                                        <a className={classes('child-link')} href={'/company'}>
                                            Company
                                        </a>
                                        <a className={classes('child-link')} href={'/partnership-tax-return-sa800'}>
                                            Partnership
                                        </a>
                                    </div>
                                    <div className={classes('child-group')}>
                                        <a className={classes('group-title')}>Who We Support</a>
                                        <a className={classes('child-link')} href={'/buy-to-let'}>
                                            Landlords
                                        </a>
                                        <a className={classes('child-link')} href={'/landlord-non-res'}>
                                            Non Resident Landlords
                                        </a>
                                        <a className={classes('child-link')} href={'/non-res-foreign'}>
                                            Expats
                                        </a>
                                        <a className={classes('child-link')} href={'/self-employed'}>
                                            Sole Traders
                                        </a>
                                        <a className={classes('child-link')} href={'/high-earner'}>
                                            Employees
                                        </a>
                                        <a className={classes('child-link')} href={'/company'}>
                                            LTD Directors
                                        </a>
                                    </div>
                                </AnimateHeight>
                            </div>
                            <div className={classes('menu-item', { open: currentExpanded == 'resources' })}>
                                <a
                                    href={'#'}
                                    className={classes({ 'child-open': currentExpanded == 'resources' })}
                                    onClick={() =>
                                        setCurrentExpanded(currentExpanded == 'resources' ? null : 'resources')
                                    }
                                >
                                    Resources
                                    <ChevronDown className={classes('chevron-down')} />
                                </a>

                                <AnimateHeight
                                    animateOpacity={true}
                                    duration={300}
                                    height={currentExpanded == 'resources' ? 'auto' : 0}
                                    contentClassName={classes('child-menu')}
                                >
                                    <div className={classes('child-group', 'calculators-box')}>
                                        <a className={classes('group-title')} href={'/calculators'}>
                                            Calculators
                                        </a>
                                        <a className={classes('child-link')} href={'/uk-tax-residence-calculator'}>
                                            Tax residence Calculator
                                        </a>
                                        <a className={classes('child-link')} href={'/remittance-basis-calculator'}>
                                            Remittance basis Calculator
                                        </a>
                                        <a className={classes('child-link')} href={'/home-pay-calculator'}>
                                            Take home pay Calculator
                                        </a>
                                        <a className={classes('child-link')} href={'/pension-calculator'}>
                                            Pension charge Calculator
                                        </a>
                                        <a className={classes('view-all')} href={'/calculators'}>
                                            View all calculators
                                        </a>
                                    </div>
                                    <div className={classes('child-group')}>
                                        <a className={classes('group-title')} href={'/blog'}>
                                            Blogs
                                        </a>
                                        <a className={classes('child-link')} href={'/blog'}>
                                            View all articles
                                        </a>
                                    </div>
                                    <div className={classes('child-group')}>
                                        <span className={classes('group-title')}>Useful links</span>
                                        <a className={classes('child-link')} href={'/about-us'}>
                                            About us
                                        </a>
                                        <a className={classes('child-link')} href={'/faqs'}>
                                            FAQs
                                        </a>
                                    </div>
                                </AnimateHeight>
                            </div>
                            <div className={classes('menu-item')}>
                                <a href={'https://dashboard.taxd.co.uk/'}>Login</a>
                            </div>
                        </div>
                        <div className={classes('bottom-section')}>
                            {button && (
                                <Button
                                    href={button.url}
                                    target={button.newTab ? '_blank' : null}
                                    {...button}
                                    type={'primary'}
                                    extraClasses={classes('button')}
                                />
                            )}
                            <hr />
                            <div className={classes('social-icons')}>
                                <a href="https://www.linkedin.com/company/74033281" target="__blank">
                                    <LinkedIn />
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=100069521803579" target="__blank">
                                    <Facebook />
                                </a>
                                <a href="https://www.instagram.com/taxduk/" target="__blank">
                                    <Instagram />
                                </a>
                            </div>
                            <span className={classes('tagline')}>Your personal, digital accountant</span>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>,
        document.body
    )
}

export default MobileMenuButton
